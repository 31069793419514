import Vue from 'vue'
import VueRouter from 'vue-router'
import manage from './dynamic-router'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  ...manage,
  {
    path: '/login',
    component: ()=>import('@/views/login.vue'),
  },
  {
    path: '/403',
    component: ()=>import('@/components/errorPage/403.vue'),
  },
  {
    path: '*',
    component: ()=>import('@/components/errorPage/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫，实现权限验证。拦截
router.beforeEach((to, from, next) => {
  console.log('store.state',store.state)
  console.log('to.path',to)
  if (!store.state.UserToken) {
    if (to.path == '/login' || to.path == '/') {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})


export default router
