/**
 * 订单
 * name：接口名称
 * url：接口api
 * method：接口请求方法，默认GET
 * defaultParams：默认参数
 */

 export default [
  {
    /** 店内订单明细 */
    name: 'getOrderList',
    url: '/api/InitData/filter',
    method: 'POST'
  },
  {
    /** 综合营业统计 */
    name: 'getOrderGroup',
    url: '/api/initdata/group',
    method: 'POST'
  },
  {
    /** 修改订单是否展示 */
    name: 'updateOrderShowStatus',
    url: '/api/initdata/remove',
    method: 'POST'
  },
  {
    /** 重新生成 */
    name: 'reIndex',
    url: '/api/initdata/reindex',
    method: 'POST'
  },
  {
    /** 导出订单明细 */
    name: 'export',
    url: '/api/initdata/export',
    method: 'POST',
    download: '店内订单明细'
  },
  {
    /** 查询门店列表 */
    name: 'getStoreList',
    url: '/api/Store/list',
    method: 'POST'
  },
  {
    /** 同步门店数据 */
    name: 'work',
    url: '/api/InitData/work',
    method: 'POST'
  },
  {
    /** 获取门店设置 */
    name: 'getSetting',
    url: '/api/Store/setting',
    method: 'POST'
  },
  {
    /** 保存门店设置 */
    name: 'saveSetting',
    url: '/api/Store/SetStoreAutoSetting',
    method: 'POST'
  },
  {
    /** 查询美团门店列表 */
    name: 'notNewStoreList',
    url: '/api/Store/NotNewStoreList',
    method: 'POST'
  },
  {
    /** 提交新增门店 */
    name: 'insertStore',
    url: '/api/Store/InsertStore',
    method: 'POST'
  },
  {
    /** 同步美团门店 */
    name: 'getStores',
    url: '/api/InitData/GetStores',
    method: 'POST'
  },
  {
    /** 获取订单详情 */
    name: 'getOrderDetail',
    url: '/api/InitData/OrderDetail',
    method: 'POST'
  },
  {
    /** 同步美团门店 */
    name: 'getStoreMenuList',
    url: '/api/Store/StoreMenuList',
    method: 'POST'
  },
  {
    /** 同步美团门店 */
    name: 'getAuthCodeUrl',
    url: '/api/InitData/GetAuthCodeUrl',
    method: 'POST'
  },
  {
    /** 新增/编辑用户管理门店 */
    name: 'UpdateUserStore',
    url: '/api/Store/UpdateUserStore',
    method: 'POST'
  },
  {
    /** 获取财务信息 */
    name: 'GetUserStoreList',
    url: '/api/Store/GetUserStoreList',
    method: 'POST'
  },
  {
    /** 获取财务信息 */
    name: 'GetUserStoreInfo',
    url: '/api/Store/GetUserStoreInfo',
    method: 'POST'
  }
  
]
