import axios from '@/utils/http'
import userList from '@/utils/userList'
import router from '@/router'
import store from '../store'
import qs from 'qs'
import login from './module/login'
import order from './module/order' //首页
const api = {
  login,
  order
}
/** 遍历api处理接口 */
Object.keys(api).forEach(module => {
  let arr = api[module]

  let moduleDict = {}

  arr.forEach(item => {
    let { name, url, method, defaultParams, config: defaultConfig, json, download } = item
    method = (method || 'GET').toLowerCase()

    /**
     * @param {object} params api参数对象
     * @param {array} pathParams api路径参数，比如配置接口：'/api/aam/v1/mgmt/role-hierachy/{@}/roles/{@}/resources'，需要传入['a', 'b']来替换两个{@}
     * @param {object} config 其他配置项，同axios官方配置config
     * @usage 使用：在Vue组件中直接使用 this.$api.moduleName.apiFunction(params, pathParams, config).then(res => {})
     */
    moduleDict[name] = (params = {}, pathParams = [], config = {}) => {
      // 下载内容
      if (download) {
        return axios[method](url, params, { responseType: 'blob' }).then(res => {
          let url = window.URL.createObjectURL(
            new Blob([res], {
              type: 'application/vnd.ms-excel;charset=utf-8'
            })
          )
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', download || '文件.xlsx')
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
          document.body.removeChild(link)
        })
      }

      let isArray = Array.isArray(params)
      let isFormData = params instanceof FormData
      if (!isFormData && !isArray && defaultParams) {
        params = Object.assign({}, defaultParams, params)
      }

      if (defaultConfig) {
        config = Object.assign({}, defaultConfig, params)
      }

      // 拷贝一个url
      let _url = url

      // 替换url中path的'{@}'
      if (_url.includes('{@}') && Array.isArray(pathParams)) {
        let urlStrList = _url.split('{@}')
        _url = ''
        urlStrList.forEach((str, index) => {
          _url += str
          pathParams[index] && (_url += pathParams[index])
        })
      }
      if (json) {
        return axios[method](_url, qs.stringify(params), config)
      }
      return axios[method](_url, params, config)
    }
  })

  api[module] = moduleDict
})

api.axios = axios
api.logout = function() {
  store.commit('SET_USER_TOKEN','')
  sessionStorage.clear()
  localStorage.clear()
  setTimeout(() => {
    router.push({
      path: '/login'
    })
  }, 200)
}
// api.login = function(data) {
//   return new Promise((resolve, reject) => {
//     let {userName,password} = data
//     let matchUser = {}
//     matchUser = userList.find(v=>v.userName===userName && v.password===password)
//     if(matchUser && matchUser.userName){
//       store.commit('SET_USER_TOKEN','112233')
//       store.commit('SET_USER_INFO',matchUser)
//       resolve(matchUser)
//     }else{
//       reject()
//     }
//   })
// }
/** api直接挂载在Vue.prototype上面 */
export default api
