<template>
  <Form ref="pwdForm" :model="form" :rules="rules" @keydown.enter.native="handleSubmit">
    <FormItem prop="oldPassword">
      <Input type="password" v-model="form.oldPassword" placeholder="请输入原密码"> </Input>
    </FormItem>
    <FormItem prop="newPassword">
      <Input type="password" v-model="form.newPassword" placeholder="请输入新密码"> </Input>
    </FormItem>
    <FormItem prop="newPasswordConfirm">
      <Input type="password" v-model="form.newPasswordConfirm" placeholder="请输入确认密码"> </Input>
    </FormItem>
    <FormItem>
      <Button @click="handleSubmit" type="primary" long>提交修改</Button>
    </FormItem>
  </Form>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'PwdForm2',
  props: {
    userName: {
      type: String,
      default: ''
    }
  },
  data () {
    const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('确认密码不能为空'));
        } else {
          if (this.form.newPassword !== value) {
            callback(new Error('两次密码不一致'));
          }else{
            callback();
          }
        }
    };
    return {
      form: {
        userName: '',
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        pattern: 1 //0：添加账号，1修改账号
      },
      rules:{
        oldPassword: [
          { required: true, message: '原密码不能为空', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '新密码不能为空', trigger: 'blur' }
        ],
        newPasswordConfirm: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ]
      }
    }
  },
  computed: {
    ...mapState(['UserInfo'])
    
  },
  methods: {
    handleSubmit () {
      this.$refs.pwdForm.validate((valid) => {
        if (valid) {
          // this.$emit('on-success-valid', {
          //   userName: this.form.userName,
          //   password: this.form.password
          // })
          if(this.userName){
            // 修改财务的账号密码
            this.form.userName = this.userName
          } else {
            // 修改自己的账号密码
            this.form.userName = this.UserInfo.userName
          }
          this.form.orgId = this.orgId
          this.$api.login.AddUser(this.form).then(res=>{
            if(res.code==200){
              this.$Message.success('修改成功！')
              this.$emit('close')
            }else{
              this.$Message.error( res.message || '修改失败！')
            }
          })
        }
      })
    }
  }
}
</script>
