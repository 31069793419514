import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    UserToken:"",
    UserInfo:{},
    menuList:[],
    orgId:'',
    _storeList:[]
  },
  mutations: {
    SET_USER_TOKEN(state,UserToken) {
			state.UserToken = UserToken
		},
    SET_USER_INFO(state,UserInfo) {
			state.UserInfo = UserInfo
		},
    SET_MENU_LIST(state,menuList) {
			state.menuList = menuList
		},
    SET_ORG_ID(state,orgId) {
			state.orgId = orgId
		},
    SET_STORE_LIST(state,storeList) {
			state._storeList = storeList
		},
  },
  actions: {
  },
  modules: {
  },
  plugins:[
    createPersistedstate({
      key: 'vuex'
    })
  ]
})
