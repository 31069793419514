const cs = require.context('./common', false, /\.vue$/)
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
export default {
  install(Vue) {
    cs.keys().forEach((item) => {
      let c = cs(item).default
      Vue.component(c.name, c)
    })
    Vue.use(ViewUI)
  },
}
