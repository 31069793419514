<template>
  <div class="breadcrumb">
    <Breadcrumb>
      <template v-for="(item, index) in crumbList">
        <Breadcrumb-item :key="index">{{ item.meta.title }}</Breadcrumb-item>
      </template>
    </Breadcrumb>
  </div>
</template>

<script>
import menu from '@/router/dynamic-router'
export default {
  name: 'breadcrumb',
  data() {
    return {
      crumbList: []
    }
  },
  watch: {
    $route: {
      handler(n) {
        menu.map((item) => {
          item.children.map((item2) => {
            if (item2.meta.title == n.meta.title) {
              this.crumbList = [item, item2]
            }
          })
        })
      },
      immediate: true
    }
  },
}
</script>

<style lang="less" scoped>
.breadcrumb {
  height: 34px;
  line-height: 34px;
  background: #ffffff;
  box-shadow: 0px 4px 6px 0px #eef3fb;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 20px;
  margin: 6px 0 6px 0;
}
</style>
